import {
  DEFAULT_BREAKDOWN,
  DEFAULT_BUDGET_PERFORMANCE_PERIOD_ID,
  DEFAULT_REPORT_PERIOD,
  DEFAULT_SEARCH_INPUT_VALUE,
} from 'pages/TeamDashboard/constants';
import { AppSettingsMap } from './types';

export const MAX_SETTINGS = 100;

/**
 * Enums do not allow template literal types
 * If this become possible in the future change constants to something like
 * `${PPD_NETWORK_SUMMARY_TABLE_ID}.${FILTER_ID}`
 *
 * BE CAREFUL WHEN CHANGING ANY OF THESE CONSTANTS!
 */
export enum Setting {
  /**
   * PPD GLOBAL SETTINGS
   */
  PPDEndDate = 'ppd.end-date',
  PPDBreakdown = 'ppd.breakdown',
  PPDStartDate = 'ppd.start-date',
  PPDSearchInput = 'ppd.search-input',
  PPDReportPeriodId = 'ppd.report-period-id',
  /* Overview */
  PPDOverviewFilters = 'ppd.overview.filters',
  PPDGoalsGeneratedChartSelectedGoals = 'ppd.goals-generated-chart.selected-goals',
  /* Performance per Day Table */
  PPDPerformancePerDayTableSorts = 'ppd.performance-per-day-table.sorts',
  PPDPerformancePerDayTableFilters = 'ppd.performance-per-day-table.filters',
  PPDPerformancePerDayTableRowsPerPage = 'ppd.performance-per-day-table.rows-per-page',
  PPDPerformancePerDayTableColumnsOrder = 'ppd.performance-per-day-table.columns-order',
  PPDPerformancePerDayTablePinnedColumns = 'ppd.performance-per-day-table.pinned-columns',
  PPDPerformancePerDayTableVisibleColumns = 'ppd.performance-per-day-table.visible-columns',
  /* Network Summary Table */
  PPDNetworkSummaryTableSorts = 'ppd.network-summary-table.sorts',
  PPDNetworkSummaryTableFilters = 'ppd.network-summary-table.filters',
  PPDNetworkSummaryTableRowsPerPage = 'ppd.network-summary-table.rows-per-page',
  PPDNetworkSummaryTableColumnsOrder = 'ppd.network-summary-table.columns-order',
  PPDNetworkSummaryTablePinnedColumns = 'ppd.network-summary-table.pinned-columns',
  PPDNetworkSummaryTableVisibleColumns = 'ppd.network-summary-table.visible-columns',
  /* Campaign Performance Table */
  PPDCampaignPerformanceTableSorts = 'ppd.campaign-performance-table.sorts',
  PPDCampaignPerformanceTableFilters = 'ppd.campaign-performance-table.filters',
  PPDCampaignPerformanceTableRowsPerPage = 'ppd.campaign-performance-table.rows-per-page',
  PPDCampaignPerformanceTableColumnsOrder = 'ppd.campaign-performance-table.columns-order',
  PPDCampaignPerformanceTablePinnedColumns = 'ppd.campaign-performance-table.pinned-columns',
  PPDCampaignPerformanceTableVisibleColumns = 'ppd.campaign-performance-table.visible-columns',
  /* Budget Performance */
  PPDBudgetPerformanceSelectedBudgetIDs = 'ppd.budget-performance.selected-budget-ids',
  /* Budget Performance Distribution Heatmap */
  PPDBudgetPerformanceHeatmapSelectedCell = 'ppd.budget-performance-heatmap.selected-cell',
  /* Budget Performance Table */
  PPDBudgetPerformanceTableSorts = 'ppd.budget-performance-table.sorts',
  PPDBudgetPerformanceTableFilters = 'ppd.budget-performance-table.filters',
  PPDBudgetPerformanceTableRowsPerPage = 'ppd.budget-performance-table.rows-per-page',
  PPDBudgetPerformanceTableColumnsOrder = 'ppd.budget-performance-table.columns-order',
  PPDBudgetPerformanceTablePinnedColumns = 'ppd.budget-performance-table.pinned-columns',
  PPDBudgetPerformanceTableVisibleColumns = 'ppd.budget-performance-table.visible-columns',
  PPDBudgetPerformanceTablePerformancePeriodId = 'ppd.budget-performance-table.performance-periodId',
  /* Campaign Settings Table */
  PPDCampaignSettingsTableSorts = 'ppd.campaign-settings-table.sorts',
  PPDCampaignSettingsTableFilters = 'ppd.campaign-settings-table.filters',
  PPDCampaignSettingsTableRowsPerPage = 'ppd.campaign-settings-table.rows-per-page',
  PPDCampaignSettingsTableColumnsOrder = 'ppd.campaign-settings-table.columns-order',
  PPDCampaignSettingsTablePinnedColumns = 'ppd.campaign-settings-table.pinned-columns',
  PPDCampaignSettingsTableVisibleColumns = 'ppd.campaign-settings-table.visible-columns',
  /* Location Settings Table */
  PPDLocationSettingsTableSorts = 'ppd.location-settings-table.sorts',
  PPDLocationSettingsTableFilters = 'ppd.location-settings-table.filters',
  PPDLocationSettingsTableRowsPerPage = 'ppd.location-settings-table.rows-per-page',
  PPDLocationSettingsTableColumnsOrder = 'ppd.location-settings-table.columns-order',
  PPDLocationSettingsTablePinnedColumns = 'ppd.location-settings-table.pinned-columns',
  PPDLocationSettingsTableVisibleColumns = 'ppd.location-settings-table.visible-columns',
}

export const DEFAULT_APP_SETTINGS: AppSettingsMap = {
  [Setting.PPDBreakdown]: { breakdown: DEFAULT_BREAKDOWN },
  [Setting.PPDSearchInput]: { searchInput: DEFAULT_SEARCH_INPUT_VALUE },
  [Setting.PPDGoalsGeneratedChartSelectedGoals]: { selectedGoals: null },
  [Setting.PPDBudgetPerformanceSelectedBudgetIDs]: { selectedBudgetIDs: null },
  [Setting.PPDEndDate]: { endDate: DEFAULT_REPORT_PERIOD.endDate?.toISOString() },
  [Setting.PPDStartDate]: { startDate: DEFAULT_REPORT_PERIOD.startDate?.toISOString() },
  // Budget Performance Table
  [Setting.PPDBudgetPerformanceTablePerformancePeriodId]: { id: DEFAULT_BUDGET_PERFORMANCE_PERIOD_ID },
  [Setting.PPDBudgetPerformanceTablePinnedColumns]: { left: ['advertiser'], right: [] },
  [Setting.PPDBudgetPerformanceTableVisibleColumns]: {
    advertiserPrettyId: false,
    budgetName: false,
    budgetPrettyId: false,
    totalBudget: false,
  },
};
