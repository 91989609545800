import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';

const GET_SESSION_DATA_QUERY = gql(/* GraphQL */ `
  query getSessionData {
    sessionUser {
      id
      _id
      modified
      ddtId
      theme
      locale
      isStaff
    }
    sessionTeam {
      id
      modified
      owner {
        ddtId
      }
    }
  }
`);

interface GetSessionQueryData {
  sessionUser: Pick<UserNode, 'id' | '_id' | 'ddtId' | 'theme' | 'locale' | 'isStaff'>;
  sessionTeam: Pick<TeamNode, 'id'> & { owner: Pick<UserNode, 'ddtId'> };
}

export const useGetSessionData = () => {
  return useLazyQuery<GetSessionQueryData>(GET_SESSION_DATA_QUERY);
};
