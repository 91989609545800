import styled from '@emotion/styled';
import { Popover } from '@mui/material';
import { FunctionComponent, KeyboardEventHandler, MouseEvent, useState } from 'react';

import { config, themeNames } from 'appConfig';
import { UserTeamAvatar } from 'shared/UserTeamAvatar';
import { HeaderMenuContent } from './HeaderMenuContent/HeaderMenuContent';
import { HEADER_NAVIGATION_USER_BUTTON_ID } from 'components/Header/HeaderMenuToggle/constants';

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  :focus {
    outline: none;

    :focus-visible {
      outline: 1px solid black;
    }
  }
`;

const StyledUserTeamAvatar = styled(UserTeamAvatar)`
  .team-avatar {
    // FIXME when we implement secondary colors properly
    border-color: ${({ theme }) =>
      config.theme === themeNames.default ? theme.palette.common.black : theme.palette.primary.main};
  }
`;

type Content = 'navigation' | 'teams';

export const HeaderMenuToggle: FunctionComponent = () => {
  const [content, setContent] = useState<Content>('navigation');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onUsernameKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      handleClose();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <StyledButton
        className="header__user-wrapper"
        aria-describedby={HEADER_NAVIGATION_USER_BUTTON_ID}
        data-testid={HEADER_NAVIGATION_USER_BUTTON_ID}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={onUsernameKeyPress}
      >
        <StyledUserTeamAvatar />
        <span>&nbsp;&#9660;</span>
      </StyledButton>
      <Popover
        id={HEADER_NAVIGATION_USER_BUTTON_ID}
        data-testid="header-navigation-user-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 55,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionProps={{
          mountOnEnter: true,
          onExited: () => {
            if (content !== 'navigation') {
              setContent('navigation');
            }
          },
        }}
      >
        <HeaderMenuContent content={content} onClose={handleClose} setContent={setContent} />
      </Popover>
    </>
  );
};
