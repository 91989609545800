import {
  CircularProgress,
  IconButton,
  IconButtonProps,
  IconButtonTypeMap,
  SvgIcon,
  SvgIconProps,
  SvgIconTypeMap,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { ElementType } from 'react';

type TooltipProsType = Omit<TooltipProps, 'title' | 'children'> & {
  title?: TooltipProps['title'];
};

export type DefaultSVGIconProps<
  C extends ElementType = SvgIconTypeMap['defaultComponent'],
  IB extends ElementType = IconButtonTypeMap['defaultComponent'],
> = SvgIconProps<C, { component?: C }> & {
  tooltipProps?: TooltipProsType;
  // The <IconButton /> component has a new prop called `loading` in Material UI 6
  // @see {@link https://mui.com/material-ui/react-progress/#circular}
  iconButtonProps?: IconButtonProps<IB, { component?: IB; loading?: boolean }>;
};

export function DefaultSVGIcon<
  C extends ElementType = SvgIconTypeMap['defaultComponent'],
  IB extends ElementType = IconButtonTypeMap['defaultComponent'],
>({ tooltipProps, iconButtonProps, ...rest }: DefaultSVGIconProps<C, IB>) {
  if (!tooltipProps || !tooltipProps.title) {
    return <SvgIcon {...rest} />;
  }

  const loading = iconButtonProps?.loading ?? false;

  return (
    <Tooltip placement="top" {...tooltipProps} title={tooltipProps.title}>
      <IconButton size="small" {...iconButtonProps}>
        {loading ? <CircularProgress size={2.5} /> : <SvgIcon fontSize="inherit" {...rest} />}
      </IconButton>
    </Tooltip>
  );
}
