import type { GraphQLFormattedError } from 'graphql/index';
import type { ApolloError } from '@apollo/client';
import { isApolloError } from '@apollo/client/errors';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { isAPIErrorCode } from 'utils/APIErrorCodes/utils';
import { logError } from 'utils/logging';
import type { DataObject } from 'utils/APIErrorCodes/types';
import { useAuth } from 'components/App/AuthProvider';
import { isError } from 'typeDeclarations/typeGuards';

type ErrorHandlingFunction<TApiErrorCode extends APIErrorCode> = (data: DataObject<TApiErrorCode>) => void;

type ErrorHandlersMap = {
  [TKey in APIErrorCode]?: ErrorHandlingFunction<TKey>;
};

type UseDefaultOnErrorCallback = (
  error: Error | ApolloError | ReadonlyArray<GraphQLFormattedError>,
  opts?: {
    defaultErrorMessage?: string;
    errorHandlers?: ErrorHandlersMap;
  },
) => void;

export function useDefaultOnError() {
  const { enqueueSnackbar } = useSnackbar();

  const { formatMessage, formatAPIErrorCode } = useExtendedIntl();

  const { session } = useAuth();

  const displaySnackbarError = useCallback(
    (errorMessage: string) => {
      enqueueSnackbar(errorMessage, { variant: 'error', 'aria-label': 'snackbar-error' });
    },
    [enqueueSnackbar],
  );

  const handleUnknownErrorCode = useCallback(
    (errorCode: string, errorMessage: string) => {
      displaySnackbarError(errorMessage);
      logError(new Error(`Unknown error code '${errorCode}'`));
    },
    [displaySnackbarError],
  );

  const handleKnownErrorCode = useCallback(
    (
      errorCode: APIErrorCode,
      errorData: DataObject<APIErrorCode>,
      fallbackMessage: string,
      opts?: { errorHandlers?: ErrorHandlersMap },
    ) => {
      const customErrorHandler = opts?.errorHandlers?.[errorCode] as
        | ErrorHandlingFunction<typeof errorCode>
        | undefined;

      if (customErrorHandler) {
        customErrorHandler(errorData);
        return;
      }

      displaySnackbarError(
        formatAPIErrorCode({
          errorCode,
          data: errorData,
          fallbackMessage,
        }),
      );
    },
    [formatAPIErrorCode, displaySnackbarError],
  );

  return useCallback<UseDefaultOnErrorCallback>(
    (error, opts) => {
      let errorMessage = opts?.defaultErrorMessage ?? formatMessage({ id: 'shared.default-error-message' });

      // It's a generic error
      if (isError(error) && !isApolloError(error)) {
        displaySnackbarError(errorMessage);
        return;
      }

      // Otherwise it's an Apollo error or a GraphQLError[]
      const graphQLErrors = isError(error) ? error.graphQLErrors : error;
      const networkError = isError(error) ? error.networkError : null;

      if (!graphQLErrors.length || networkError) {
        displaySnackbarError(errorMessage);
        return;
      }

      const errorsCatalog = new APIErrorCodesCatalog(graphQLErrors);
      const catalogedErrorCodes = errorsCatalog.getErrorCodes();

      for (const errorCode of catalogedErrorCodes) {
        errorMessage = session?.user.isStaff ? errorCode : errorMessage;

        if (!isAPIErrorCode(errorCode)) {
          handleUnknownErrorCode(errorCode, errorMessage);
          return;
        }

        const errorData = errorsCatalog.getErrorData(errorCode);

        if (errorData) {
          handleKnownErrorCode(errorCode, errorData, errorMessage, opts);
          return;
        }

        displaySnackbarError(errorMessage);
      }
    },
    [handleUnknownErrorCode, handleKnownErrorCode, session, displaySnackbarError, formatMessage],
  );
}
